<template>
  <div class="product-list template-homepage">
    <div v-if="title" class="product-list__title">
      {{ title }}
    </div>
    <div class="product-list__items">
      <Product
        v-for="(item, index) in displayedItems"
        :key="item.id"
        :item="item"
        :queryID="queryID"
        :position="index"
      />
    </div>
  </div>
</template>

<script>
import Resolutions from '@/mixins/resolutions';
import Product from '../components/Product/Product';
import { getBiggestDivisible } from '@/misc/utils';

export default {
  name: 'TemplateHomepage',
  components: {
    Product
  },
  mixins: [Resolutions],
  props: {
    title: { type: String },
    items: { type: Array },
    queryID: { type: String, default: null }
  },
  computed: {
    displayedItems: function () {
      if (this.isSmMinus) {
        return this.items.slice(0, getBiggestDivisible(2, this.items.length));
      }
      if (this.isMdMinus) {
        return this.items.slice(0, getBiggestDivisible(3, this.items.length));
      }
      if (this.isLgMinus) {
        return this.items.slice(0, getBiggestDivisible(4, this.items.length));
      }
      return this.items.slice(0, getBiggestDivisible(5, this.items.length));
    }
  }
};
</script>

<style lang="scss" scoped src="./product-list.scss"></style>
